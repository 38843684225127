import React from "react";
import { Icon } from "@iconify/react";
import { Box, SxProps } from "@mui/material";

interface Props {
  icon: string;
  width?: number | string;
  height?: number | string;
  sx?: SxProps;
}

const Iconify = ({ icon, sx, ...other }: Props) => {
  return (
    <Box component={Icon} icon={icon} sx={{ ...sx }} {...other} />
    // <Icon
    //   icon={props.icon}
    //   width={props.width || 30}
    //   height={props.height || 30}
    // />
  );
};

export default Iconify;
