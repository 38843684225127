/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Grid } from "gridjs-react";
import { _ } from "gridjs-react";
import "gridjs/dist/theme/mermaid.css";
import { BusinessSearchResult } from "../../../interfaces/business";
import { BASE_URL } from "../../../util/const";
import { useAuth } from "../../../context/auth";
import Iconify from "../../../components/Icon";
import {
  Button,
  Card,
  FormControl,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Link, useNavigate, Navigate } from "react-router-dom";
import { CasetRequest } from "../../../interfaces/caset";
import { LoadingButton } from "@mui/lab";
import { FormikProvider, Form, useFormik } from "formik";
import toast from "react-hot-toast";

function createData(
  name: string,
  calories: number,
  fat: number,
  carbs: number,
  protein: number
) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

const tableColumns = [
  "Id",
  "Business Name",
  "Created At",
  "Request Amount",
  "Request Percent",
  "Request Tenure",
  "Status",
];

export default function CreateRequestTable() {
  const { authRequest } = useAuth();
  const navigate = useNavigate();
  const [showCasetRequestModal, setShowCasetRequestModal] = useState(false);
  const [showCasetCreateModal, setShowCasetCreateModal] = useState(false);
  const [casetRequests, setCasetRequests] = useState([]);
  const [casetModalData, setCasetModalData] = useState<CasetRequest | null>(
    null
  );

  const ModifyCasetRequestSchema = Yup.object().shape({});

  const CreateCasetRequestSchema = Yup.object().shape({
    requestPercent: Yup.number().positive().min(1).max(100),
    requestAmount: Yup.number().min(1).positive(),
    requestTenure: Yup.number().integer().min(1).positive().max(12),
    status: Yup.string().required(),
  });

  const createCasetSchema = Yup.object().shape({
    category: Yup.string().required(),
    casetBuyStartDate: Yup.date().required(),
    unitPrice: Yup.number().min(1).positive().required(),
    unitPercentReturns: Yup.number().positive().required(),
    revenuePercentShare: Yup.number().min(1).positive().required(),
    startDate: Yup.date().required(),
    endDate: Yup.date().required(),
    totalUnits: Yup.number().min(1).positive().required(),
    validity: Yup.number().min(1).positive().required(),
  });

  const casetCreateFormik = useFormik({
    initialValues: {
      category: "",
      casetBuyStartDate: String(new Date()),
      unitPrice: 0,
      unitPercentReturns: 0,
      revenuePercentShare: 0,
      startDate: String(new Date()),
      endDate: String(new Date()),
      totalUnits: 0,
      validity: 1,
    },
    validationSchema: createCasetSchema,
    onSubmit: (values) => {
      console.log(values);
      if (!casetModalData) return;
      console.log("Submitting", values);
      casetCreateFormik.setSubmitting(true);
      authRequest(`caset/`, "POST", {
        businessId: casetModalData.businessUser.business.id,
        casetRequestId: casetModalData.id,
        category: values.category,
        casetBuyStartDate: new Date(values.casetBuyStartDate),
        unitPrice: Number(values.unitPrice),
        unitPercentReturns: Number(values.unitPercentReturns),
        revenuePercentShare: Number(values.revenuePercentShare),
        startDate: new Date(values.startDate),
        endDate: new Date(values.endDate),
        totalUnits: Number(values.totalUnits),
        validity: Number(values.validity),
      })
        .then((data) => {
          toast.success("Added Successfully");
          casetCreateFormik.setSubmitting(false);
          casetCreateFormik.resetForm();
          formik.resetForm();
          setShowCasetRequestModal(false);
          setShowCasetCreateModal(false);
        })
        .catch((err) => {
          toast.error(err.message);
          casetCreateFormik.setSubmitting(false);
        });
      //   setTimeout(() => {
      //     formik.setSubmitting(false);
      //   }, 2000);
    },
  });

  const formik = useFormik({
    initialValues: {
      businessName: "",
      createdAt: new Date(),
      requestAmount: 0,
      requestPercent: 0,
      requestTenure: 0,
      status: "pending",
    },
    validationSchema: CreateCasetRequestSchema,
    onSubmit: (values) => {
      // navigate('/dashboard', { replace: true });
      if (!casetModalData) return;
      console.log("Submitting", values);
      formik.setSubmitting(true);
      authRequest(`caset/${casetModalData.id}/request`, "PATCH", {
        requestAmount: Number(values.requestAmount),
        requestPercent: Number(values.requestPercent),
        requestTenure: Number(values.requestTenure),
        status: values.status,
      })
        .then((data) => {
          toast.success("Added Successfully");
          formik.setSubmitting(false);
          //   formik.resetForm();
          //   setShowCasetRequestModal(false);
        })
        .catch((err) => {
          toast.error(err.message);
          formik.setSubmitting(false);
        });
      //   setTimeout(() => {
      //     formik.setSubmitting(false);
      //   }, 2000);
    },
  });

  useEffect(() => {
    if (casetModalData) {
      formik.resetForm();
      formik.setValues({
        businessName: casetModalData.businessUser.business.name,
        createdAt: new Date(casetModalData.createdAt),
        requestAmount: casetModalData.requestAmount,
        requestPercent: casetModalData.requestPercent,
        requestTenure: casetModalData.requestTenure,
        status: "pending",
      });
    }
  }, [casetModalData]);

  //   const getCasetsRequestData= ()=>{

  //   }
  //   useEffect(()=>{
  //     getCasetsRequestData();
  //   },[]);

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } =
    formik;

  const {
    errors: errors2,
    touched: touched2,
    values: values2,
    isSubmitting: isSubmitting2,
    handleSubmit: handleSubmit2,
    getFieldProps: getFieldProps2,
  } = casetCreateFormik;

  //   const navigation = useNavigate();
  //   const history = useHistory();
  //   const [businesses, setBusinesses] = useState();
  return (
    <>
      <Modal
        open={showCasetCreateModal}
        sx={{ display: "grid", placeContent: "center" }}
      >
        <Card sx={{ minWidth: "max(300px, calc(50vw))", padding: 2 }}>
          <Button
            sx={{
              float: "right",
            }}
            onClick={() => {
              setShowCasetCreateModal(false);
            }}
          >
            Close
          </Button>
          <Typography variant="h4" sx={{ marginBottom: 2 }}>
            Create Caset
          </Typography>
          <FormikProvider value={casetCreateFormik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit2}>
              <Stack
                spacing={3}
                direction={{
                  xs: "column",
                  sm: "column",
                  lg: "column",
                  //   xl: "row",
                }}
                // flexWrap={"wrap"}
              >
                <TextField
                  fullWidth
                  label="Category"
                  {...getFieldProps2("category")}
                  error={Boolean(touched2.category && errors2.category)}
                  helperText={touched2.category && errors2.category}
                />

                <TextField
                  fullWidth
                  type={"date"}
                  label="Caset Buy Start Date"
                  {...getFieldProps2("casetBuyStartDate")}
                  error={Boolean(
                    touched2.casetBuyStartDate && errors2.casetBuyStartDate
                  )}
                  helperText={
                    touched2.casetBuyStartDate && errors2.casetBuyStartDate
                  }
                />

                <TextField
                  fullWidth
                  label="Unit Price"
                  {...getFieldProps2("unitPrice")}
                  error={Boolean(touched2.unitPrice && errors2.unitPrice)}
                  helperText={touched2.unitPrice && errors2.unitPrice}
                />

                <TextField
                  fullWidth
                  label="Unit percent returns"
                  {...getFieldProps2("unitPercentReturns")}
                  error={Boolean(
                    touched2.unitPercentReturns && errors2.unitPercentReturns
                  )}
                  helperText={
                    touched2.unitPercentReturns && errors2.unitPercentReturns
                  }
                />

                <TextField
                  fullWidth
                  label="Revenue Percent Share"
                  {...getFieldProps2("revenuePercentShare")}
                  error={Boolean(
                    touched2.revenuePercentShare && errors2.revenuePercentShare
                  )}
                  helperText={
                    touched2.revenuePercentShare && errors2.revenuePercentShare
                  }
                />

                <TextField
                  fullWidth
                  type={"date"}
                  label="Caset Start Date"
                  {...getFieldProps2("startDate")}
                  error={Boolean(touched2.startDate && errors2.startDate)}
                  helperText={touched2.startDate && errors2.startDate}
                />

                <TextField
                  fullWidth
                  type={"date"}
                  label="Caset End Date"
                  {...getFieldProps2("endDate")}
                  error={Boolean(touched2.endDate && errors2.endDate)}
                  helperText={touched2.endDate && errors2.endDate}
                />

                <TextField
                  fullWidth
                  label="Total units"
                  {...getFieldProps2("totalUnits")}
                  error={Boolean(touched2.totalUnits && errors2.totalUnits)}
                  helperText={touched2.totalUnits && errors2.totalUnits}
                />

                <TextField
                  fullWidth
                  label="Validity"
                  {...getFieldProps2("validity")}
                  error={Boolean(touched2.validity && errors2.validity)}
                  helperText={touched2.validity && errors2.validity}
                />
              </Stack>

              <LoadingButton
                fullWidth
                sx={{
                  marginTop: 2,
                }}
                onClick={() => {
                  console.log(values);
                }}
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
              >
                Create Caset
              </LoadingButton>
            </Form>
          </FormikProvider>
        </Card>
      </Modal>
      <Modal
        sx={{ display: "grid", placeContent: "center" }}
        open={showCasetRequestModal}
      >
        <Card sx={{ minWidth: "max(300px, calc(50vw))", padding: 2 }}>
          <Button
            sx={{
              float: "right",
            }}
            onClick={() => {
              setShowCasetRequestModal(false);
            }}
          >
            Close
          </Button>
          <Typography variant="h4" sx={{ marginBottom: 2 }}>
            Caset Request
          </Typography>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Stack
                spacing={3}
                direction={{
                  xs: "column",
                  sm: "column",
                  lg: "column",
                  //   xl: "row",
                }}
                // flexWrap={"wrap"}
              >
                <TextField
                  fullWidth
                  disabled
                  label="Business Name"
                  {...getFieldProps("businessName")}
                  error={Boolean(touched.businessName && errors.businessName)}
                  helperText={touched.businessName && errors.businessName}
                />

                <TextField
                  fullWidth
                  type={"text"}
                  disabled
                  label="Created Date"
                  {...getFieldProps("createdAt")}
                  placeholder={""}
                  error={Boolean(touched.createdAt && errors.createdAt)}
                  //   helperText={touched.foundedDate && errors.foundedDate}
                />

                <TextField
                  fullWidth
                  type={"number"}
                  label="Request amount"
                  {...getFieldProps("requestAmount")}
                  error={Boolean(touched.requestAmount && errors.requestAmount)}
                  helperText={touched.requestAmount && errors.requestAmount}
                />

                <TextField
                  fullWidth
                  label="Request Percent"
                  {...getFieldProps("requestPercent")}
                  error={Boolean(
                    touched.requestPercent && errors.requestPercent
                  )}
                  helperText={touched.requestPercent && errors.requestPercent}
                />

                <TextField
                  fullWidth
                  type="number"
                  label="Request Tenure"
                  {...getFieldProps("requestTenure")}
                  error={Boolean(touched.requestTenure && errors.requestTenure)}
                  helperText={touched.requestTenure && errors.requestTenure}
                />

                <FormControl fullWidth>
                  <Typography>Status</Typography>

                  {/* <InputLabel id="demo-simple-select-label">Country of Operation</InputLabel> */}
                  <Select
                    labelId="demo-simple-select-label"
                    defaultValue={"pending"}
                    {...getFieldProps("status")}
                    // label={'Country of Operation'}
                  >
                    <MenuItem value={"pending"}>Pending</MenuItem>
                    <MenuItem value={"rejected"}>Rejected</MenuItem>
                  </Select>
                </FormControl>
              </Stack>

              <LoadingButton
                fullWidth
                sx={{
                  marginTop: 2,
                }}
                onClick={() => {
                  console.log(values);
                }}
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
              >
                Save Changes
              </LoadingButton>
              <Button
                fullWidth
                variant="contained"
                onClick={() => {
                  setShowCasetCreateModal(true);
                }}
              >
                Accept and create caset
              </Button>
            </Form>
          </FormikProvider>
        </Card>
      </Modal>
      <Card sx={{ padding: 2 }}>
        <Typography variant="h4">Caset Request Table</Typography>
        <Grid
          server={{
            url: "caset/request/pending?",
            data: (opts) => {
              return new Promise((s, f) => {
                authRequest(opts.url, "GET", {}).then((data) => {
                  s({
                    data: data.map((casetRequest: CasetRequest) => [
                      _(
                        <a
                          href="#"
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            setShowCasetRequestModal(true);
                            setCasetModalData(casetRequest);
                          }}
                        >
                          {casetRequest.id}
                        </a>
                      ),
                      casetRequest.businessUser.business.name,
                      new Date(casetRequest.createdAt).toLocaleDateString(),
                      casetRequest.requestAmount,
                      casetRequest.requestPercent,
                      casetRequest.requestTenure,
                      _(
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            navigate(`/business/kyc/${casetRequest.id}`);
                          }}
                        >
                          {casetRequest.status}
                        </a>
                      ),
                    ]),
                    total: data.length,
                  });
                });
              });
            },
          }}
          columns={tableColumns}
          // search={{
          //   server: {
          //     url: (prev, keyword) => `${prev}&search=${keyword}`,
          //   },
          // }}
          //   sort={{
          //     multiColumn: false,
          //     server: {
          //       url: (prev, columns) => {
          //         if (!columns.length) return prev;

          //         const col = columns[0];
          //         const dir = col.direction === 1 ? "asc" : "desc";
          //         let colName = tableColumns[col.index];

          //         return `${prev}&order=${colName}&dir=${dir}`;
          //       },
          //     },
          //   }}
          // pagination={{
          //   enabled: true,
          //   limit: 5,
          //   server: {
          //     url: (prev, page, limit) =>
          //       `${prev}&limit=${limit}&offset=${page * limit}`,
          //   },
          // }}
        />
      </Card>
    </>
  );
}
