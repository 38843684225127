import { Navigate, useRoutes, RouteObject } from "react-router-dom";
import { useAuth } from "./context/auth";
import DashboardLayout from "./layouts/dashboard";
import Login from "./pages/Login";
import KycVerification from "./pages/sections/business/KYCVerification";
import BusinessPage from "./pages/sections/dashboard/Business";
import DashboardApp from "./pages/sections/dashboard/DashboardApp";

const Routes = () => {
  const { isLoggedIn, authData } = useAuth();
  const user = authData?.user || {};

  const protectRoute = (component: JSX.Element) => {
    return isLoggedIn ? component : <Navigate to="/login" />;
  };

  return useRoutes([
    {
      path: "/",
      children: [
        {
          path: "",
          element: isLoggedIn ? <Navigate to="/dashboard/app" /> : <Login />,
        },
        {
          path: "login",
          element: isLoggedIn ? <Navigate to="/dashboard/app" /> : <Login />,
        },
      ],
      // Dashboard Page
      // Business
      // Create Business and business user
      // Business KYC
      // Profile
    },
    {
      path: "/dashboard",
      element: protectRoute(<DashboardLayout />),
      children: [
        {
          path: "",
          element: <DashboardApp />,
        },
        {
          path: "app",
          element: <DashboardApp />,
        },
        {
          path: "business",
          element: <BusinessPage />,
        },
      ],
    },
    {
      path: "/business",
      children: [
        {
          path: "kyc/:id",
          element: <KycVerification />,
        },
      ],
    },
    {
      path: "/caset",
      children: [
        {
          path: ":id/steps",
          element: <KycVerification />,
        },
      ],
    },
  ]);
};

export default Routes;
