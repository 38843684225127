import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";
import Logo from "../components/Logo";
import Page from "../components/Page";
import LoginForm from "./sections/auth/LoginForm";

const RootStyle = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  & > * {
    flex-grow: 1;
  }

  & > div:nth-of-type(2){
    display:'grid'; 
    place-content: 'baseline';
    /* place-content: "center"; */
  }

  @media screen and (max-width: 767px) {
    & {
      flex-direction: column;
    }

    & > div:nth-of-type(2){
        /* align-self: flex-start; */
        place-content: 'baseline'
    }
  }
`;

const Login = () => {
  return ( 
    <Page title="Login">
      <RootStyle>
        <Logo />
        <div>
          <LoginForm />
        </div>
      </RootStyle>
    </Page>
  );
};

export default Login;
