import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import Routes from "./routes";
import { AuthProvider } from "./context/auth";
import { GlobalProvider } from "./context/global";

function App() {
  return (
    <HelmetProvider>
      <GlobalProvider>
        <AuthProvider>
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        </AuthProvider>
      </GlobalProvider>
    </HelmetProvider>
  );
}

export default App;
