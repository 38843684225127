import styled from "@emotion/styled";
import { Box, Stack } from "@mui/material";
import Page from "../../../components/Page";
import AddBusiness from "./AddBusiness";
import BusinessListTable from "./BusinessListTable";
import CasetRequestTable from "./CasetRequestTable";
import CasetListTable from "./CasetTable";

const RootStyle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const BusinessPage = () => {
  return (
    <Page title="Dashboard">
      <RootStyle>
        <AddBusiness />
        <BusinessListTable />
        {/* Casets table */}
        <CasetListTable />
        <CasetRequestTable />
      </RootStyle>
    </Page>
  );
};

export default BusinessPage;
