import React from "react";
import CASET_LOGO from "../assets/images/Caset Logo-white text.svg";
import CASET_LOGO_BLACK_TEXT from "../assets/images/Caset Logo-black text.svg";

interface Props {
  fill?: string;
  width?: number;
  height?: number;
}

const Logo = (props: Props) => {
  const { width, height } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || 250}
      height={height || 100}
      viewBox="0 0 119 52"
      {...props}
    >
      <defs>
        <linearGradient
          id="b"
          x1={0.141}
          y1={1}
          x2={0.963}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#e8bb7f" />
          <stop offset={1} stopColor="#bbfd08" />
        </linearGradient>
        <clipPath id="a">
          <path d="M0 0h119v52H0z" />
        </clipPath>
      </defs>
      <g data-name="Caset Logo" clipPath="url(#a)">
        <g data-name="Rectangle 769" fill="none" stroke="#707070">
          <path stroke="none" d="M-32-35h176V89H-32z" />
          <path d="M-31.5-34.5h175v123h-175z" />
        </g>
        <g data-name="Group 1108">
          <g data-name="Group 1030">
            <g data-name="Group 1025" transform="translate(-398.991 -530.582)">
              <text
                transform="rotate(-30 1236.093 -570.738)"
                fill={props.fill || "#000"}
                fontSize={40}
                fontFamily="DMSans-Medium, DM Sans"
                fontWeight={500}
              >
                <tspan x={0} y={40}>
                  {"e"}
                </tspan>
              </text>
              <text
                transform="translate(485 532)"
                fill={props.fill || "#000"}
                fontSize={40}
                fontFamily="DMSans-Bold, DM Sans"
                fontWeight={700}
              >
                <tspan x={0} y={40}>
                  {"t"}
                </tspan>
              </text>
              <text
                transform="translate(447 532)"
                fill={props.fill || "#000"}
                fontSize={40}
                fontFamily="DMSans-Bold, DM Sans"
                fontWeight={700}
              >
                <tspan x={0} y={40}>
                  {"s"}
                </tspan>
              </text>
              <text
                transform="translate(427 532)"
                fill={props.fill || "#000"}
                fontSize={40}
                fontFamily="DMSans-Bold, DM Sans"
                fontWeight={700}
              >
                <tspan x={0} y={40}>
                  {"a"}
                </tspan>
              </text>
              <text
                transform="translate(400 533)"
                fill={props.fill || "#000"}
                fontSize={40}
                fontFamily="DMSans-Bold, DM Sans"
                fontWeight={700}
              >
                <tspan x={0} y={40}>
                  {"C"}
                </tspan>
              </text>
              <text
                data-name="."
                transform="translate(500 532)"
                fill="#95e813"
                fontSize={40}
                fontFamily="DMSans-Medium, DM Sans"
                fontWeight={500}
              >
                <tspan x={0} y={40}>
                  {"."}
                </tspan>
              </text>
              <circle
                data-name="Ellipse 30"
                cx={3}
                cy={3}
                r={3}
                transform="translate(501 566.582)"
                stroke="rgba(0,0,0,0)"
                strokeMiterlimit={10}
                fill="url(#b)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Logo;
