import {
  Box,
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Link,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import toast from "react-hot-toast";
import React, { useState } from "react";
import { useAuth } from "../../../context/auth";
import { DatePicker, LoadingButton, MobileDatePicker } from "@mui/lab";
import Iconify from "../../../components/Icon";

const AddBusiness = () => {
  const { authRequest } = useAuth();
  const [showBusinessAddModal, setShowBusinessAddModal] = useState(false);

  const BusinessSchema = Yup.object().shape({
    name: Yup.string().min(3).required("Name is required"),
    foundedDate: Yup.date().required(),
    accentColor: Yup.string().required(),
    subLocation: Yup.string().min(3).required("Sub Location is required"),
    source: Yup.string().required(),
    rating: Yup.number().required(),
    userEmail: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    userPhoneNumber: Yup.string()
      .required("Phone number is required")
      .length(10, "Phone number should be 10 digits")
      .matches(/^[0-9]{10}$/, "Phone number should be 10 digits"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      foundedDate: new Date(),
      accentColor: "",
      subLocation: "",
      source: "",
      rating: 0,
      userPhoneNumber: "",
      userEmail: "",
    },
    validationSchema: BusinessSchema,
    onSubmit: (values) => {
      // navigate('/dashboard', { replace: true });
      console.log("Submitting");
      formik.setSubmitting(true);
      authRequest("business", "POST", {
        ...values,
        foundedDate: new Date(values.foundedDate),
      })
        .then((data) => {
          toast.success("Added Successfully");
          formik.setSubmitting(false);
          formik.resetForm();
          setShowBusinessAddModal(false);
        })
        .catch((err) => {
          toast.error(err.message);
          formik.setSubmitting(false);
        });
      // setTimeout(() => {
      //   formik.setSubmitting(false);
      // }, 2000);
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } =
    formik;

  return (
    <>
      <Modal
        sx={{ display: "grid", placeContent: "center" }}
        open={showBusinessAddModal}
      >
        <Card sx={{ minWidth: "max(300px, calc(50vw))", padding: 2 }}>
          <Button
            sx={{
              float: "right",
            }}
            onClick={() => {
              setShowBusinessAddModal(false);
            }}
          >
            Close
          </Button>
          <Typography variant="h4" sx={{ marginBottom: 2 }}>
            Add Business
          </Typography>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Stack
                spacing={3}
                direction={{
                  xs: "column",
                  sm: "column",
                  lg: "column",
                  //   xl: "row",
                }}
                // flexWrap={"wrap"}
              >
                <TextField
                  fullWidth
                  label="Business Name"
                  {...getFieldProps("name")}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />

                <TextField
                  fullWidth
                  type={"date"}
                  label="Founded Date"
                  {...getFieldProps("foundedDate")}
                  placeholder={""}
                  error={Boolean(touched.foundedDate && errors.foundedDate)}
                  //   helperText={touched.foundedDate && errors.foundedDate}
                />

                <TextField
                  fullWidth
                  type={"color"}
                  label="Accent Color"
                  {...getFieldProps("accentColor")}
                  error={Boolean(touched.accentColor && errors.accentColor)}
                  helperText={touched.accentColor && errors.accentColor}
                />

                <TextField
                  fullWidth
                  label="Sub Location"
                  {...getFieldProps("subLocation")}
                  error={Boolean(touched.subLocation && errors.subLocation)}
                  helperText={touched.subLocation && errors.subLocation}
                />

                <TextField
                  fullWidth
                  label="Source"
                  {...getFieldProps("source")}
                  error={Boolean(touched.source && errors.source)}
                  helperText={touched.source && errors.source}
                />

                <TextField
                  fullWidth
                  type="number"
                  label="Rating"
                  {...getFieldProps("rating")}
                  error={Boolean(touched.rating && errors.rating)}
                  helperText={touched.rating && errors.rating}
                />

                <TextField
                  fullWidth
                  autoComplete="username"
                  type="email"
                  label="User Email address"
                  {...getFieldProps("userEmail")}
                  error={Boolean(touched.userEmail && errors.userEmail)}
                  helperText={touched.userEmail && errors.userEmail}
                />

                <TextField
                  fullWidth
                  autoComplete="phone"
                  type="text"
                  inputProps={{
                    maxLength: 10,
                  }}
                  label="User Phone Number"
                  {...getFieldProps("userPhoneNumber")}
                  error={Boolean(
                    touched.userPhoneNumber && errors.userPhoneNumber
                  )}
                  helperText={touched.userPhoneNumber && errors.userPhoneNumber}
                />
              </Stack>

              <LoadingButton
                fullWidth
                sx={{
                  marginTop: 2,
                }}
                onClick={() => {
                  console.log(values);
                }}
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
              >
                Save Business
              </LoadingButton>
            </Form>
          </FormikProvider>
        </Card>
      </Modal>

      <Box display={"flex"} justifyContent={"flex-end"}>
        <Button
          variant="contained"
          onClick={() => {
            setShowBusinessAddModal(true);
          }}
        >
          Add Business
        </Button>
      </Box>
    </>
  );
};

export default AddBusiness;
