import { Box, InputLabel, Stack } from "@mui/material";
import { RefObject } from "preact";
import React, { LegacyRef, useEffect, useRef, useState } from "react";
import { useAuth } from "../context/auth";
import Iconify from "./Icon";

interface Props {
  fileUrl: string;
  fileName: string;
  handleDownload: () => void;
}

const DownloadFile = ({ fileUrl, fileName, handleDownload }: Props) => {
  //   const handleDownload = (e: any) => {
  //     // if (aRef && aRef.current && ref && ref.current && ref.current.files.length) {
  //     //   console.log('Download calling');
  //     //   URL.revokeObjectURL(aRef.current.href);
  //     //   const files = ref.current.files;
  //     //   const blob = new Blob([files[0]]);
  //     //   const url = URL.createObjectURL(blob);
  //     //   aRef.current.href = url;
  //     //   aRef.current.click();
  //     // }
  //     if (e) e.stopPropagation();
  //     if (handleFileDownload) {
  //       handleFileDownload();
  //     }
  //   };

  // const handleFileChange = (e) => {
  //   const files = e.target.files;
  //   console.log(files);
  //   if (onChange) {
  //     onChange(files[0]);
  //   }
  // };
  return (
    <Box
      flexGrow={1}
      alignSelf={"center"}
      sx={{
        borderRadius: 2,
        maxWidth: 130,
        padding: 2,
        border: "1px solid black",
        marginLeft: "10px",
        minWidth: 260,
      }}
    >
      <InputLabel
        onClick={() => {
          // handleLabelClick();
          handleDownload();
        }}
        sx={{
          minWidth: 150,
          display: "flex",
          flexDirectionL: "row",
          alignItems: "center",
        }}
        variant={"standard"}
      >
        <div>
          <Iconify icon={"fluent:attach-20-regular"} width={20} height={20} />
        </div>
        {fileName}
        {fileUrl ? (
          <div
            // onClick={handleDownload}
            style={{
              position: "absolute",
              right: "0",
              background: "white",
              paddingLeft: "10px",
            }}
          >
            <Iconify
              icon={"ant-design:download-outlined"}
              width={20}
              height={20}
            />
          </div>
        ) : (
          ""
        )}
        {/* {fileName ? <div><Iconify icon={'carbon:close-filled'} width={20} height={20} /></div> : ''} */}
      </InputLabel>
      {/* <a
        href="#"
        ref={aRef}
        target="_blank"
        download={fileName}
        style={{ display: "none" }}
      >
        none
      </a> */}
    </Box>
  );
};

export default DownloadFile;
