import styled from "@emotion/styled";
import {
  Box,
  Avatar,
  Typography,
  Link,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  Icon,
  ListItemText,
} from "@mui/material";
import React from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import Iconify from "../../components/Icon";
import Logo from "../../components/Logo";
import NavSection from "../../components/NavSection";
import useResponsive from "../../hooks/useResponsive";

const DRAWER_WIDTH = 280;

const RootStyle = styled.div`
  @media screen and (min-width: 1200px) {
    flex-shrink: 0;
    width: ${DRAWER_WIDTH + "px"};
  }
`;

const getIcon = (name: string) => (
  <Iconify icon={name} width={30} height={30} />
);

interface Props {
  isOpenSidebar: boolean;
  onCloseSidebar: () => void;
}

const DashboardSidebar = ({ isOpenSidebar, onCloseSidebar }: Props) => {
  const { pathname } = useLocation();
  const navConfig = [
    {
      title: "dashboard",
      path: "/dashboard/app",
      icon: getIcon("eva:pie-chart-2-fill"),
    },
    {
      title: "business",
      path: "/dashboard/business",
      icon: getIcon("ic:outline-business"),
    },
  ];
  const isDesktop = useResponsive("up", "lg");

  const renderContent = (
    <Box
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Box sx={{ px: 2.5, py: 3, display: "inline-flex" }}>
        <Logo />
      </Box>

      {/* <Box sx={{ mb: 5, mx: 2.5 }}>
        <Link underline="none" component={RouterLink} to="#">
          <AccountStyle>
            <Avatar src={account.photoURL} alt="photoURL" />
            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: "text.primary" }}>
                {user.name}
              </Typography>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                {account.role}
              </Typography>
            </Box>
          </AccountStyle>
        </Link>
      </Box> */}

      <NavSection navConfig={navConfig} />

      <Box sx={{ flexGrow: 1 }} />
    </Box>
  );

  return (
    <RootStyle>
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: "background.default",
              borderRightStyle: "dashed",
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
};

export default DashboardSidebar;
