/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import useState from "react";
import { Grid } from "gridjs-react";
import { _ } from "gridjs-react";
import "gridjs/dist/theme/mermaid.css";
import { BusinessSearchResult } from "../../../interfaces/business";
import { BASE_URL } from "../../../util/const";
import { useAuth } from "../../../context/auth";
import Iconify from "../../../components/Icon";
import { Card, Typography } from "@mui/material";
import { Link, useNavigate, Navigate } from "react-router-dom";

function createData(
  name: string,
  calories: number,
  fat: number,
  carbs: number,
  protein: number
) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

const tableColumns = [
  "Name",
  "FoundedDate",
  "Type",
  "Industry",
  "Kyc Status",
  "Is Kyc verified",
  "Website",
  "Rating",
];

export default function BusinessListTable() {
  const { authRequest } = useAuth();
  const navigate = useNavigate();
  //   const navigation = useNavigate();
  //   const history = useHistory();
  //   const [businesses, setBusinesses] = useState();
  return (
    <Card sx={{ padding: 2 }}>
      <Typography variant="h4">Businesses</Typography>
      <Grid
        server={{
          url: "business?",
          data: (opts) => {
            return new Promise((s, f) => {
              authRequest(opts.url, "GET", {}).then((data) => {
                s({
                  data: data.results.map((business: BusinessSearchResult) => [
                    _(
                      <a
                        href="#"
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                        }}
                      >
                        {business.name}
                      </a>
                    ),
                    new Date(business.foundedDate).toLocaleDateString(),
                    business.businessType,
                    business.industryType,
                    _(
                      //   <Navigate to={`business/kyc/${business.id}`} />
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          navigate(`/business/kyc/${business.id}`);
                        }}
                      >
                        {business.kycStatus}
                      </a>
                      //   <Link to={`business/kyc/${business.id}`}>
                      //     {business.kycStatus}
                      //   </Link>
                    ),
                    _(
                      business.isKycVerified ? (
                        <Iconify icon="ant-design:check-circle-twotone" />
                      ) : (
                        <Iconify icon="emojione-monotone:cross-mark-button" />
                      )
                    ),
                    business.website_url,
                    business.rating.rating,
                  ]),
                  total: data.total,
                });
              });
            });
          },
        }}
        columns={tableColumns}
        search={{
          server: {
            url: (prev, keyword) => `${prev}&search=${keyword}`,
          },
        }}
        //   sort={{
        //     multiColumn: false,
        //     server: {
        //       url: (prev, columns) => {
        //         if (!columns.length) return prev;

        //         const col = columns[0];
        //         const dir = col.direction === 1 ? "asc" : "desc";
        //         let colName = tableColumns[col.index];

        //         return `${prev}&order=${colName}&dir=${dir}`;
        //       },
        //     },
        //   }}
        pagination={{
          enabled: true,
          limit: 5,
          server: {
            url: (prev, page, limit) =>
              `${prev}&limit=${limit}&offset=${page * limit}`,
          },
        }}
      />
    </Card>
  );
}
