import React from "react";
import { Modal } from "@mui/material";
import Iconify from "./Icon";

const getIcon = (name: string) => (
  <Iconify icon={name} width={50} height={50} />
);

interface Props {
  loading: boolean;
}
// eos-icons:loading
const Loader = ({ loading }: Props) => (
  <Modal
    BackdropProps={{
      style: {
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        background: "rgba(0, 0, 0, 0.5)",
      },
    }}
    open={Boolean(loading)}
  >
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        color: "white",
        alignContent: "center",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {getIcon("eos-icons:loading")}
    </div>
  </Modal>
);

export default Loader;
