/* eslint-disable no-restricted-globals */
import { Box, Button, Card, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import DownloadFile from "../../../components/DownloadFile";
import FileInput from "../../../components/FileInput";
import Iconify from "../../../components/Icon";
import Loader from "../../../components/Loader";
import Page from "../../../components/Page";
import { useAuth } from "../../../context/auth";
import { useGlobal } from "../../../context/global";
import {
  Business,
  ValidBusinessKycSections,
} from "../../../interfaces/business";

const validSections = [
  "general_information",
  "address_information",
  "bank_verification",
  "kyc_information",
];

const KycVerification = () => {
  const { id } = useParams();
  const { authRequest, fileRequest } = useAuth();
  const { showToast } = useGlobal();
  const [isLoading, setIsLoading] = useState(false);

  const [businessData, setBusinessData] = useState<Business | null>(null);
  const [kycConfig, setKycConfig] = useState<any>(null);

  const [sectionsStatus, setSectionsStatus] = useState<any>({});

  useEffect(() => {
    setIsLoading(true);
    Promise.all([getBusinessData(), getKycConfig()])
      .then(() => {
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    if (businessData) {
      const sectionsData = businessData.kycJson.sectionStatus || {};
      setSectionsStatus(sectionsData);
    }
  }, [businessData]);

  const getBusinessData = () => {
    return authRequest(`business/${id}`, "GET", {})
      .then((data) => {
        if (!data) return history.back();
        setBusinessData(data);
      })
      .catch((err) => {
        // navigate.;
        history.back();
      });
  };

  const getKycConfig = () => {
    return authRequest(`business/merchant/kyc/layout`, "GET", {})
      .then((data) => {
        setKycConfig(data.kycConf || null);
      })
      .catch((err) => {
        // navigate.;
        history.back();
      });
  };

  const approveSection = (section: ValidBusinessKycSections) => {
    setIsLoading(true);
    return authRequest(`business/${id}/kyc/${section}/accept`, "POST", {})
      .then(async (data) => {
        // setKycConfig(data.kycConf || null);
        console.log("Success ");
        await getBusinessData();
        setIsLoading(false);

        toast.success("Success");
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.message || `${section} approve failed`);
      });
  };

  const rejectSection = (section: ValidBusinessKycSections) => {
    setIsLoading(true);
    const reason = prompt("Enter reason");
    if (!reason?.trim()) return toast.error("Invalid reason");
    return authRequest(
      `business/${id}/kyc/${section}/reject?reason=${reason}`,
      "POST",
      {}
    )
      .then(async (data) => {
        // setKycConfig(data.kycConf || null);
        await getBusinessData();
        setIsLoading(false);
        toast.success("Success");
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(`${section} reject failed`);
      });
  };

  const kycVerificationSubmit = () => {
    setIsLoading(true);

    return authRequest(`business/${id}/kyc/submit`, "POST", {})
      .then(async (data) => {
        // setKycConfig(data.kycConf || null);
        await getBusinessData();
        setIsLoading(false);
        toast.success("Success");
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.message || `Submission failed`);
      });
  };

  if (!businessData) {
    return <Loader loading />;
  }

  const renderSectionStatus = (section: ValidBusinessKycSections) => {
    const sectionStatus = sectionsStatus[section] || {
      status: "pending",
    };

    const status = sectionStatus.status;

    if (status === "approved")
      return (
        <Iconify
          icon={"akar-icons:circle-check-fill"}
          sx={{
            color: "green",
          }}
        />
      );

    if (status === "rejected")
      return (
        <Iconify
          icon="ic:outline-cancel"
          sx={{
            color: "red",
          }}
        />
      );

    return (
      <Iconify
        icon={"dashicons:warning"}
        sx={{
          color: "yellow",
        }}
      />
    );
  };

  const renderApproveReject = (section: ValidBusinessKycSections) => {
    return (
      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={2}
        alignSelf={"center"}
      >
        <Box flexGrow={1}>
          <Button
            onClick={() => {
              approveSection(section);
            }}
            variant="contained"
          >
            Accept
          </Button>
        </Box>
        <Box flexGrow={1}>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              rejectSection(section);
            }}
          >
            Reject
          </Button>
        </Box>
      </Stack>
    );
  };

  return (
    <>
      <Loader loading={isLoading} />
      <Page title="Business KYC">
        <Stack spacing={2} sx={{ padding: 2, marginBottom: 10 }}>
          <Typography variant="h3">Business KYC</Typography>
          <Card sx={{ padding: 2 }}>
            <Stack spacing={2}>
              <Typography variant="h4">
                General Information {renderSectionStatus("general_information")}
              </Typography>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <Box flexGrow={1}>
                  <Typography>Business Name</Typography>
                  <TextField fullWidth disabled value={businessData.name} />
                </Box>
                <Box flexGrow={1}>
                  <Typography>Country of Operation</Typography>
                  <TextField fullWidth disabled value={"India"} />
                </Box>
              </Stack>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <Box flexGrow={1}>
                  <Typography>Business Type</Typography>
                  <TextField
                    fullWidth
                    disabled
                    value={businessData.businessType}
                  />
                </Box>
                <Box flexGrow={1}>
                  <Typography>Industry Type</Typography>
                  <TextField
                    fullWidth
                    disabled
                    value={businessData.industryType}
                  />
                </Box>
              </Stack>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <Box flexGrow={1}>
                  <Typography>Website</Typography>
                  <TextField
                    fullWidth
                    disabled
                    value={businessData.website_url}
                  />
                </Box>
              </Stack>

              {renderApproveReject("general_information")}
            </Stack>
          </Card>

          {/* Address Details */}
          <Card sx={{ padding: 2 }}>
            <Stack spacing={2}>
              <Typography variant="h4">
                Address Verification{" "}
                {renderSectionStatus("address_information")}
              </Typography>
              <Typography variant="h4">Business Address</Typography>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <Box flexGrow={1}>
                  <Typography>Address</Typography>
                  <TextField
                    fullWidth
                    disabled
                    value={businessData.address.address}
                  />
                </Box>
                <Box flexGrow={1}>
                  <Typography>City</Typography>
                  <TextField
                    fullWidth
                    disabled
                    value={businessData.address.city}
                  />
                </Box>
              </Stack>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <Box flexGrow={1}>
                  <Typography>State</Typography>
                  <TextField
                    fullWidth
                    disabled
                    value={businessData.address.state}
                  />
                </Box>
                <Box flexGrow={1}>
                  <Typography>Pincode</Typography>
                  <TextField
                    fullWidth
                    disabled
                    value={businessData.address.pincode}
                  />
                </Box>
              </Stack>

              <Typography variant="h4">Business GST Address</Typography>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <Box flexGrow={1}>
                  <Typography>Address</Typography>
                  <TextField
                    fullWidth
                    disabled
                    value={businessData.gstAddress.address}
                  />
                </Box>
                <Box flexGrow={1}>
                  <Typography>City</Typography>
                  <TextField
                    fullWidth
                    disabled
                    value={businessData.gstAddress.city}
                  />
                </Box>
              </Stack>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <Box flexGrow={1}>
                  <Typography>State</Typography>
                  <TextField
                    fullWidth
                    disabled
                    value={businessData.gstAddress.state}
                  />
                </Box>
                <Box flexGrow={1}>
                  <Typography>Pincode</Typography>
                  <TextField
                    fullWidth
                    disabled
                    value={businessData.gstAddress.pincode}
                  />
                </Box>
              </Stack>

              {renderApproveReject("address_information")}
            </Stack>
          </Card>

          {/* Bank Details */}
          <Card sx={{ padding: 2 }}>
            <Stack spacing={2}>
              <Typography variant="h4">
                Bank Verification {renderSectionStatus("bank_verification")}
              </Typography>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <Box flexGrow={1}>
                  <Typography>Account</Typography>
                  <TextField
                    fullWidth
                    disabled
                    value={businessData.wallet.bankAccount}
                  />
                </Box>
                <Box flexGrow={1}>
                  <Typography>IFSC</Typography>
                  <TextField
                    fullWidth
                    disabled
                    value={businessData.wallet.ifsc}
                  />
                </Box>
              </Stack>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <Box flexGrow={1}>
                  <Typography>Account Holder Name</Typography>
                  <TextField
                    fullWidth
                    disabled
                    value={businessData.wallet.accountHolderName}
                  />
                </Box>
              </Stack>

              {renderApproveReject("bank_verification")}
            </Stack>
          </Card>

          {/* KYC Data verification */}
          {kycConfig ? (
            <Card sx={{ padding: 2 }}>
              <Stack spacing={2}>
                <Typography variant="h4">
                  KYC Information {renderSectionStatus("kyc_information")}
                </Typography>
                {/* <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
              
            </Stack> */}
                {kycConfig[businessData.businessType].map((r: any) => {
                  const fields = r["fields"];
                  const kycJson = businessData.kycJson;
                  return (
                    <Stack spacing={2}>
                      <Typography>
                        {r.title} {""}
                      </Typography>
                      {fields.map((f: any) => {
                        const fieldData = kycJson[f.key];
                        return (
                          <Box flexGrow={1}>
                            {f.type === "file" && fieldData ? (
                              <DownloadFile
                                fileName={fieldData.originalName}
                                fileUrl={fieldData.fileUrl}
                                handleDownload={async () => {
                                  await fileRequest(
                                    `business/file?url=${encodeURIComponent(
                                      fieldData.fileUrl
                                    )}`,
                                    fieldData.originalName,
                                    true
                                  );
                                }}
                              />
                            ) : (
                              <TextField
                                fullWidth
                                disabled
                                value={kycJson[f.key] || ""}
                              />
                            )}
                          </Box>
                        );
                      })}
                    </Stack>
                  );
                })}
                {renderApproveReject("kyc_information")}
              </Stack>
            </Card>
          ) : null}

          <Button onClick={kycVerificationSubmit} variant="contained">
            Submit
          </Button>
        </Stack>
      </Page>
    </>
  );
};

export default KycVerification;
