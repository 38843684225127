import { Outlet } from "react-router-dom";

import styled from "@emotion/styled";
import DashboardSidebar from "./DashboardSidebar";
import DashboardNavbar from "./DashboardNavbar";
import { useState } from "react";

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled.div`
  display: flex;
  min-height: 100vh;
  overflow: hidden;
`;

const MainStyle = styled.div`
  flex-grow: 1;
  overflow: auto;
  min-height: 100%;
  padding-top: ${APP_BAR_MOBILE + 24 + "px"};
  padding-bottom: 5rem;
  padding-left: 24px;
  padding-right: 24px;
  @media screen and (min-width: 1200px) {
    padding-top: ${APP_BAR_DESKTOP + 24 + "px"};
    padding-left: 5rem;
    padding-right: 5rem;
  }
`;

// const MainStyle = styled('div')(({ theme }) => ({
//     flexGrow: 1,
//     overflow: 'auto',
//     minHeight: '100%',
//     paddingTop: APP_BAR_MOBILE + 24,
//     paddingBottom: theme.spacing(10),
//     [theme.breakpoints.up('lg')]: {
//       paddingTop: APP_BAR_DESKTOP + 24,
//       paddingLeft: theme.spacing(2),
//       paddingRight: theme.spacing(2)
//     }
//   }));

const DashboardLayout = () => {
  const [open, setOpen] = useState(false);
  return (
    <RootStyle>
      <DashboardSidebar
        isOpenSidebar={open}
        onCloseSidebar={() => setOpen(false)}
      />
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      <MainStyle>
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
};

export default DashboardLayout;
