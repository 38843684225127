/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useState, useEffect } from "react";
import { Grid } from "gridjs-react";
import { _ } from "gridjs-react";
import "gridjs/dist/theme/mermaid.css";
import { BusinessSearchResult } from "../../../interfaces/business";
import { BASE_URL } from "../../../util/const";
import { useAuth } from "../../../context/auth";
import Iconify from "../../../components/Icon";
import {
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Modal,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { Link, useNavigate, Navigate } from "react-router-dom";
import { ApprovalStep, Caset } from "../../../interfaces/caset";
import FileInput from "../../../components/FileInput";
import toast from "react-hot-toast";
import Loader from "../../../components/Loader";

const tableColumns = [
  "Business Name",
  "Caset ID",
  "Caset Status",
  "Is Active",
  "Created At",
];

interface StepModalProps {
  open: boolean;
  data: ApprovalStep[];
  casetId: string;
  closeModal: () => void;
  refresh?: () => void;
}

const StepApprovalModal = ({
  open,
  data,
  casetId,
  refresh,
  closeModal,
}: StepModalProps) => {
  const { formAuthRequest, authRequest, fileRequest } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [steps, setSteps] = useState<ApprovalStep[]>([]);

  useEffect(() => {
    return () => {
      if (refresh) refresh();
    };
  }, []);

  useEffect(() => {
    setSteps([...data]);
  }, [data]);

  const refreshStepsData = () => {
    return authRequest(`caset/${casetId}/steps`, "GET", {})
      .then((data) => {
        setSteps(data);
      })
      .catch((err) => {});
  };

  const handleStepStatus = (stepId: number, status: string) => {
    setIsLoading(true);
    return authRequest(`caset/step/status`, "POST", {
      casetId: casetId,
      stepId: stepId,
      status: status,
    })
      .then(async (data) => {
        await refreshStepsData();
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const handleFileUpload = (formData: any, stepId: number) => {
    setIsLoading(true);
    formAuthRequest(`caset/${casetId}/step/${stepId}/file`, "POST", formData)
      .then(async () => {
        await refreshStepsData();
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.message);
      });
  };

  const handleFileDownload = (url: string, fileName: string) => {
    setIsLoading(true);
    fileRequest(`business/file?url=${encodeURIComponent(url)}`)
      .then((blob) => {
        setIsLoading(false);
        return URL.createObjectURL(blob);
      })
      .then((href) => {
        Object.assign(document.createElement("a"), {
          href,
          download: fileName,
        }).click();
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.message);
      });
  };

  const renderSteps = React.useMemo(() => {
    return steps.map((step) => {
      const id = step.id + "";
      const extraJson: any = step.extraInfo;
      const file = extraJson.file || {};
      return (
        <Stack>
          <FormGroup>
            <FormLabel id={id}>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                {step.name}{" "}
                {step.extraInfo && step.extraInfo.fileAttachmentRequired ? (
                  <FileInput
                    fileData={extraJson.file}
                    // fileKey={f.key}
                    onChange={(file) => {
                      const data = new FormData();
                      data.append("file", file);
                      handleFileUpload(data, step.id);
                    }}
                    handleFileDownload={() => {
                      handleFileDownload(file.fileUrl, file.originalName);
                    }}
                  />
                ) : (
                  ""
                )}
              </Typography>
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby={id}
              value={step.status}
              name="radio-buttons-group"
              onChange={(event) => {
                console.log(event.target.value);
                // if (refresh) refresh();
                handleStepStatus(step.id, event.target.value);
              }}
            >
              <FormControlLabel
                value="pending"
                control={<Radio />}
                label="Pending"
              />
              <FormControlLabel
                value="completed"
                control={<Radio />}
                label="Completed"
              />
              <FormControlLabel
                value="rejected"
                control={<Radio />}
                label="Rejected"
              />
            </RadioGroup>
            {/* <FormControlLabel label={step.name} /> */}
          </FormGroup>
        </Stack>
      );
    });
  }, [steps]);

  return (
    <Modal open={open} sx={{ display: "grid", placeContent: "center" }}>
      <Card sx={{ minWidth: "max(300px, calc(50vw))", padding: 2 }}>
        <Loader loading={isLoading} />

        <Stack>
          <div style={{ flexGrow: 1 }}></div>
          <Button
            // variant="outlined"
            sx={{
              float: "right",
              width: 100,
              alignSelf: "flex-end",
            }}
            fullWidth={false}
            onClick={closeModal}
          >
            Close
          </Button>
        </Stack>
        <Stack sx={{ padding: 2 }}>{renderSteps}</Stack>
      </Card>
    </Modal>
  );
};

export default function CasetListTable() {
  const { authRequest } = useAuth();
  const navigate = useNavigate();
  const [showStepApprovalModal, setShowStepApprovalModal] = useState(false);

  const [approvalSteps, setApprovalSteps] = useState<ApprovalStep[]>([]);
  const [casetId, setCasetId] = useState("");
  const [dummyData, setDummyData] = useState({});
  //   const navigation = useNavigate();
  //   const history = useHistory();
  //   const [businesses, setBusinesses] = useState();

  return (
    <Card sx={{ padding: 2 }}>
      <StepApprovalModal
        open={showStepApprovalModal}
        data={approvalSteps}
        casetId={casetId}
        refresh={() => {
          setDummyData({});
        }}
        closeModal={() => {
          setShowStepApprovalModal(false);
        }}
      />
      <Typography variant="h4">Casets</Typography>
      <Grid
        server={{
          url: "caset/pending?",
          data: (opts) => {
            return new Promise((s, f) => {
              authRequest(opts.url, "GET", {}).then((data) => {
                s({
                  data: data.results.map((caset: Caset) => [
                    _(
                      <a
                        href="#"
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                        }}
                      >
                        {caset.businessBranch.business.name}
                      </a>
                    ),
                    _(
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          setShowStepApprovalModal(true);
                          setApprovalSteps(caset.approvalSteps);
                          setCasetId(caset.id);
                          //   navigate(`/caset/${caset.id}/steps`);
                        }}
                      >
                        {caset.id}
                      </a>
                    ),
                    caset.status,
                    _(
                      caset.isActive ? (
                        <Iconify icon="ant-design:check-circle-twotone" />
                      ) : (
                        <Iconify icon="emojione-monotone:cross-mark-button" />
                      )
                    ),
                    new Date(caset.createdAt).toLocaleDateString(),
                  ]),
                  total: data.total,
                });
              });
            });
          },
        }}
        columns={tableColumns}
        search={{
          server: {
            url: (prev, keyword) => `${prev}&search=${keyword}`,
          },
        }}
        //   sort={{
        //     multiColumn: false,
        //     server: {
        //       url: (prev, columns) => {
        //         if (!columns.length) return prev;

        //         const col = columns[0];
        //         const dir = col.direction === 1 ? "asc" : "desc";
        //         let colName = tableColumns[col.index];

        //         return `${prev}&order=${colName}&dir=${dir}`;
        //       },
        //     },
        //   }}
        pagination={{
          enabled: true,
          limit: 5,
          server: {
            url: (prev, page, limit) =>
              `${prev}&limit=${limit}&offset=${page * limit}`,
          },
        }}
      />
    </Card>
  );
}
