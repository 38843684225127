import React, { createContext, useState, useContext, useEffect } from "react";
import { useGlobal } from "./global";
import { BASE_URL } from "../util/const";
import { storeData, getStoredData } from "../util/storage";
import { User } from "../interfaces/user";
// import {AuthData, authService} from '../services/authService';

type methods = "GET" | "POST" | "PUT" | "DELETE" | "PATCH";

type AuthContextData = {
  authData?: AuthData;
  loading: boolean;
  signIn(data: any, authenticated?: boolean): Promise<void>;
  signOut(): void;
  isLoggedIn: boolean;
  updateUserData(data: User): Promise<void>;
  request(URL: string, method: methods, data?: any): Promise<any>;
  authRequest(URL: string, method: methods, data?: any): Promise<any>;
  formAuthRequest(URL: string, method: methods, data: any): Promise<any>;
  formRequest(URL: string, method: methods, data: any): Promise<any>;
  fileRequest(
    URL: string,
    fileName?: string,
    toDownload?: boolean
  ): Promise<Blob>;
};

type AuthData = {
  access: string;
  user: User;
};

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

interface Props {
  children: React.ReactNode;
}

const AuthProvider = ({ children }: Props) => {
  const [authData, setAuthData] = useState<AuthData>();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { updateGlobalState } = useGlobal();

  //The loading part will be explained in the persist step session
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    function startSetup() {
      console.log("\n\n\n\n\n\n\nStartup setup running!\n\n\n\n\n\n\n\n");
      let token: string = getStoredData("access_token");
      if (!token) {
        return;
      }
      let user: any = getStoredData("user");
      setAuthData({
        access: token,
        user: user,
      });

      updateGlobalState("user", user);
      if (user && token) {
        console.log("Logged in");
        setIsLoggedIn(true);
      }
    }
    startSetup();
  }, []);

  async function requestValidation(resp: any) {
    if (resp.status === 401) {
      signOut();
      throw new Error("Authorization Error");
    }
    let res = await resp.json();
    if (resp.status === 405) {
      console.log(res);
      throw new Error("Invalid Method");
    }
    if (resp.status > 399) {
      console.log(res);
      if (res.message) {
        if (Array.isArray(res.message)) {
          throw new Error(res.message.join("\n"));
        } else {
          throw new Error(res.message);
        }
      } else {
        throw new Error("failed");
      }
    }
    return res;
  }

  async function request(
    URL: string,
    method: methods,
    data?: any,
    isAuthNeeded = false,
    formData = false
  ) {
    let token = getStoredData("access_token");
    if (isAuthNeeded) {
      // Auth required request
      if (formData) {
        return fetch(BASE_URL + URL, {
          method: method,
          body:
            method !== "GET"
              ? formData
                ? data
                : JSON.stringify(data || {})
              : undefined,
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }).then((resp) => {
          return requestValidation(resp);
        });
      } else {
        return fetch(BASE_URL + URL, {
          method: method,
          body:
            method !== "GET"
              ? formData
                ? data
                : JSON.stringify(data || {})
              : undefined,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }).then((resp) => {
          return requestValidation(resp);
        });
      }
    } else {
      // Not auth request
      if (formData) {
        return fetch(BASE_URL + URL, {
          method: method,
          body:
            method !== "GET"
              ? formData
                ? data
                : JSON.stringify(data || {})
              : undefined,
          headers: {
            Accept: "application/json",
          },
        }).then((resp) => {
          return requestValidation(resp);
        });
      } else {
        return fetch(BASE_URL + URL, {
          method: method,
          body: method !== "GET" ? JSON.stringify(data || {}) : undefined,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }).then((resp) => {
          return requestValidation(resp);
        });
      }
    }
  }

  function authRequest(URL: string, method: methods, data?: any) {
    return request(URL, method, data, true);
  }

  function formAuthRequest(URL: string, method: methods, data: any) {
    return request(URL, method, data, true, true);
  }

  function formRequest(URL: string, method: methods, data: any) {
    return request(URL, method, data, false, true);
  }

  const signIn = async (data: any, authenticated = false) => {
    //call the service passing credential (email and password).
    //In a real App this data will be provided by the user from some InputText components.
    // const _authData = await authService.signIn(
    //   'lucasgarcez@email.com',
    //   '123456',
    // );
    // const _authData = {};

    //Set the data in the context, so the App can be notified
    //and send the user to the AuthStack
    if (data.token) {
      storeData("access_token", data.token);
      storeData("user", data.user);
      updateGlobalState("user", data.user);
      setAuthData(data);
      setIsLoggedIn(true);
    }
    setAuthData(data);
    if (authenticated) {
      setIsLoggedIn(true);
    }
  };

  const updateUserData = async (data: User) => {
    if (authData) {
      const a = { ...authData };
      a["user"] = data;
      setAuthData(a);
      await storeData("user", data);
      updateGlobalState("user", data);
    }
  };

  useEffect(() => {
    if (!isLoggedIn) {
      updateGlobalState("user", null);
    }
  }, [isLoggedIn]);

  const signOut = async () => {
    //Remove data from context, so the App can be notified
    //and send the user to the AuthStack
    console.log("Signed Out");
    setAuthData(undefined);
    await storeData("access_token", null);
    await storeData("user", null);
    setIsLoggedIn(false);
  };

  const handleFileDownload = async (blob: Blob, fileName: string) => {
    const href = URL.createObjectURL(blob);

    Object.assign(document.createElement("a"), {
      href,
      download: fileName,
    }).click();
    return blob;
  };

  function fileRequest(URL: string, fileName?: string, toDownload?: boolean) {
    const token = getStoredData("access_token");

    return fetch(BASE_URL + URL, {
      method: "GET",
      body: undefined,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((resp) => resp.blob())
      .then((blob) => {
        if (toDownload && fileName) {
          return handleFileDownload(blob, fileName);
        }
        return blob;
      });
  }

  return (
    //This component will be used to encapsulate the whole App,
    //so all components will have access to the Context
    <AuthContext.Provider
      value={{
        authData,
        loading,
        signIn,
        signOut,
        isLoggedIn,
        request,
        authRequest,
        formAuthRequest,
        formRequest,
        updateUserData,
        fileRequest,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  return context;
}

// export const AuthProvider = _AuthProvider;
export { AuthProvider, AuthContext, useAuth };
